import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import { theme } from "../../theme";
import Line from "../../components/Line/line";
import Section from "../../components/Section/section";
import Card from "../../components/Card/card";
import { styled } from "styled-components";
import Wave from "../../components/Wave/wave";

///////////////// STYLED COMPONENTS ////////////////
const Icon = styled.img`
  text-align: center;
  height: 55px;
  margin-bottom: ${(props) => props.theme.paddings.text};
`;

//////////////////// FUNCTIONS ////////////////////
function Section2() {
  return (
    <>
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
          marginTop: { xs: "-10px", md: "-20px" },
          marginBottom: "-10px",
          width: "100%",
        }}
      >
        <Wave colour={"#bce6f6"} />
      </Box>
      <Section bg={"#bce6f6"} pt={false}>
        <Grid item xs={12} px={{ xs: "8%", md: 0 }}>
          <Line />
          <Typography variant="h2">Portl's DOOH advantage</Typography>
          <Typography variant="body1" marginX={"auto"}>
            Transforming the advertising journey with unmatched efficiency,
            precision, and innovation.
          </Typography>
        </Grid>

        <Grid container>
          {/* Card 1 */}
          <Grid item xs={12} md={3}>
            <Card
              bg={theme.colours.navyBlue}
              border={theme.colours.portlBlue}
              minHeight="290px"
            >
              <Icon src="./icons/diverse.svg" />
              <Typography variant="h3">Access uncharted venues</Typography>
              <Typography variant="body1" color="white" mb="0">
                Discover unique, underutilized ad spaces.
              </Typography>
            </Card>
          </Grid>

          {/* Card 2 */}
          <Grid item xs={12} md={3}>
            <Card
              bg={theme.colours.navyBlue}
              border={theme.colours.portlBlue}
              minHeight="290px"
            >
              <Icon src="./icons/streamline.svg" />
              <Typography variant="h3">One-stop solution</Typography>
              <Typography variant="body1" color="white" mb="0">
                Bid farewell to multi-vendor management with our consolidated
                platform.
              </Typography>
            </Card>
          </Grid>

          {/* Card 3 */}
          <Grid item xs={12} md={3}>
            <Card
              bg={theme.colours.navyBlue}
              border={theme.colours.portlBlue}
              minHeight="290px"
            >
              <Icon src="./icons/precision.svg" />
              <Typography variant="h3">Tailored targeting</Typography>
              <Typography variant="body1" color="white" mb="0">
                Ensure optimal campaign results with advanced analytics.
              </Typography>
            </Card>
          </Grid>

          {/* Card 4 */}
          <Grid item xs={12} md={3}>
            <Card
              bg={theme.colours.navyBlue}
              border={theme.colours.portlBlue}
              minHeight="290px"
            >
              <Icon src="./icons/flexibility.svg" />
              <Typography variant="h3">Agile innovation</Typography>
              <Typography variant="body1" color="white" mb="0">
                Benefit from our flexible, forward-thinking technology.
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Section>
    </>
  );
}

export default Section2;
