import React from 'react';
import { Box} from '@mui/material';
import './logoScroller.css';

export default function LogoScroller() {
    return (
        <>
        <Box className='logos'>
            <Box className='logos-slider-right'>
                <img src='./partner_logos/absolut.png' />
                <img src='./partner_logos/aperol.png' />
                <img src='./partner_logos/boston_pizza.png' />
                <img src='./partner_logos/cadbury.png' />
                <img src='./partner_logos/cibc.png' />
                <img src='./partner_logos/comcast.png' />
                <img src='./partner_logos/corby.png' />
                <img src='./partner_logos/doordash.png' />
                <img src='./partner_logos/duracell.png' />
            </Box>
            {/* duplicate needed for continuous scroll */}
            <Box className='logos-slider-right'>
                <img src='./partner_logos/absolut.png' />
                <img src='./partner_logos/aperol.png' />
                <img src='./partner_logos/boston_pizza.png' />
                <img src='./partner_logos/cadbury.png' />
                <img src='./partner_logos/cibc.png' />
                <img src='./partner_logos/comcast.png' />
                <img src='./partner_logos/corby.png' />
                <img src='./partner_logos/doordash.png' />
                <img src='./partner_logos/duracell.png' />
            </Box>
        </Box>
        <Box className='logos'>
            <Box className='logos-slider-left'>
                <img src='./partner_logos/kfc.png' />
                <img src='./partner_logos/lcbo.png' />
                <img src='./partner_logos/nestle.png' />
                <img src='./partner_logos/olg.png' />
                <img src='./partner_logos/rogers.png' />
                <img src='./partner_logos/square.png' />
                <img src='./partner_logos/sunwing.png' />
                <img src='./partner_logos/universal_st.png' />
                <img src='./partner_logos/westjet.png' />
            </Box>
            {/* duplicate needed for continuous scroll */}
            <Box className='logos-slider-left'>
                <img src='./partner_logos/kfc.png' />
                <img src='./partner_logos/lcbo.png' />
                <img src='./partner_logos/nestle.png' />
                <img src='./partner_logos/olg.png' />
                <img src='./partner_logos/rogers.png' />
                <img src='./partner_logos/square.png' />
                <img src='./partner_logos/sunwing.png' />
                <img src='./partner_logos/universal_st.png' />
                <img src='./partner_logos/westjet.png' />
            </Box>
        </Box>
        </>
    );
};