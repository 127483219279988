import React, { useState } from "react";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
import { Grid, Box } from "@mui/material";
import NavDropdown from "./navDropdown";
import "./navbar.css";
import { theme } from "../../theme";
import Logo from "../Logo/logo";

///////////////// STYLED COMPONENTS ////////////////
const LogoLink = styled(Link)`
  color: ${(props) => props.theme.colours.black};
  width: 100%;
  font-weight: 600;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavButton = styled.button`
  color: ${(props) => props.theme.colours.portlBlue};
  font-family: "Montserrat";
  font-size: ${(props) => props.theme.fontSizes.paragraph};
  font-weight: 600;
  border: none;
  /* text-shadow: white 1px 1px 15px; */
  background: none;
  &:hover {
    cursor: pointer;
  }
`;

//////////////////// FUNCTIONS ////////////////////
function NavBar({ page }: { page: string }) {
  const [isOpen, setIsOpen] = useState(false);
  /* handles nav bg color on scroll */
  const [activeNav, setActiveNav] = useState(false);
  window.addEventListener("scroll", changeNavBG);

  // changes nav bg from transparent to white on scroll
  function changeNavBG() {
    if (window.scrollY >= 120) {
      setActiveNav(true);
    } else {
      setActiveNav(false);
    }
  }

  function handleClick() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <Grid
        container
        className={
          isOpen ? "navbar isOpen" : activeNav ? "navbar active" : "navbar"
        }
        style={{ position: "fixed", zIndex: 4 }}
      >
        <Grid item xs={2} md={4}></Grid>
        <Grid
          container
          xs={8}
          md={4}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection="row"
          width={{ xs: "90%", md: "100%" }}
        >
          <Grid item mt="15px" mb="8px" maxWidth={{ xs: "90px", md: "150px" }}>
            <LogoLink to="/home">
              <Logo colour={theme.colours.portlBlue} size="42px" />
            </LogoLink>
          </Grid>
          <Grid item>
            {page != "home" && <div className="navLogoText">{page}</div>}
          </Grid>
        </Grid>
        <Grid
          container
          xs={2}
          md={4}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {/* <Grid item display={{ xs: "none", sm: "block" }} pr={3}>
            <NavButton onClick={handleClick}>Existing Partners</NavButton>
          </Grid> */}
          <Grid item>
            <NavButton onClick={handleClick}>
              <img src="./hamburger.svg" style={{ width: "80%" }} />
            </NavButton>
          </Grid>
          <Box component={Grid} item display={{ xs: "none", sm: "block" }}>
            <NavButton onClick={handleClick}>Explore</NavButton>
          </Box>
        </Grid>
      </Grid>
      {isOpen && <NavDropdown />}
    </>
  );
}

export default NavBar;
