import React from "react";
import { Typography, Grid, Box, Link } from "@mui/material";
import { Button } from "../../components/Button/button";
import Section from "../../components/Section/section";

//////////////////// FUNCTIONS ////////////////////

function HeroSection() {
  return (
    <>
      {/* Hero image for widescreen */}
      <Grid container position="absolute">
        <Grid item xs={12} md={6}></Grid>
        <Box
          component={Grid}
          item
          sm={12}
          md={6}
          display={{ xs: "none", md: "block" }}
        >
          <img src="./ads_hero.webp" style={{ width: "100%" }} />
        </Box>
      </Grid>

      {/* Text box */}
      <Section bg="transparent">
        <Grid
          item
          xs={12}
          md={6}
          pt={{ xs: 15, md: 18 }}
          pr="40px"
          pl={{ xs: "8%", md: 0 }}
          textAlign="left"
        >
          <Typography variant="h1">
            Unleash your brand's full potential
          </Typography>
          <Typography variant="body1">
            We connect your brand with the right audiences, in the right places,
            and at the right times. Our innovative DOOH advertising platform
            offers real-time analytics, precise targeting capabilities, and
            flexible campaigns.
          </Typography>
          <Grid container direction={"row"} spacing={2}>
            <Grid item>
              <Button href="/ads-signup">Get in contact</Button>
            </Grid>
            <Grid item>
              <Link
                style={{ textDecoration: "none" }}
                href="https://go.portlmedia.com/resources"
                target="_blank"
              >
                <Button kind="outline">View resources ↗</Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>

        {/* Hero image for mobile */}
        <Box
          component={Grid}
          item
          sm={12}
          md={6}
          display={{ xs: "block", md: "none" }}
          mt={8}
        >
          <img src="./ads_hero.webp" style={{ width: "100%" }} />
        </Box>

        {/* Aligns text box to half screen on widescreen */}
        <Box
          component={Grid}
          item
          sm={12}
          md={6}
          display={{ xs: "none", md: "block" }}
        ></Box>
      </Section>
    </>
  );
}

export default HeroSection;
