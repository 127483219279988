import { styled, darken } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";
import { theme } from "../../theme";

interface ButtonProps {
  // 3 button variations set by 'kind' prop:
  // 'outline', 'navy' and blue by default
  kind?: string;
}

export const Button = styled(MuiButton)<ButtonProps>(({ kind }) => ({
  border: "2px solid",
  borderRadius: 50,
  minWidth: "240px",
  color: kind === "outline" ? theme.colours.portlBlue : "white",
  backgroundColor:
    kind === "darkNavy"
      ? theme.colours.darkNavy
      : kind === "outline"
      ? "transparent"
      : kind == "navy"
      ? theme.colours.navyBlue
      : theme.colours.portlBlue,
  borderColor: kind === "outline" ? theme.colours.portlBlue : "transparent",
  textTransform: "none",
  fontFamily: theme.fontFamily,
  fontSize: theme.fontSizes.paragraph,
  padding: "12px 50px",
  "&:hover": {
    backgroundColor:
      kind === "darkNavy"
        ? darken(theme.colours.darkNavy, 0.2)
        : kind === "outline"
        ? theme.colours.lightGrey
        : kind === "navy"
        ? darken(theme.colours.navyBlue, 0.2)
        : darken(theme.colours.portlBlue, 0.2),
    color: kind === "outline" ? theme.colours.portlBlue : "white",
    borderColor: kind === "navy" ? theme.colours.portlBlue : "transparent",
  },
}));
