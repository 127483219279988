import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import { theme } from "../../theme";
import Line from "../../components/Line/line";
import Section from "../../components/Section/section";
import LogoScroller from "../../components/LogoScroller/logoScroller";
import Wave from "../../components/Wave/wave";

function Section6() {
  return (
    <Section bg={theme.colours.lightGrey}>
      <Grid item xs={12} sx={{ overflow: "hidden" }}>
        <Line />
        <Typography
          variant="body2"
          color={theme.colours.darkNavy}
          marginX="auto"
        >
          ADVERTSING PARTNERS
        </Typography>
        <LogoScroller />
      </Grid>
    </Section>
  );
}

export default Section6;
