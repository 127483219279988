import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { theme } from "../../theme";
import Line from "../../components/Line/line";
import Section from "../../components/Section/section";
import LottieAnimation from "../../components/LottieAnimation/lottieAnimation";

function Section4() {
  return (
    <Box id="location-based">
      <Section bg={theme.colours.darkNavy}>
        <Grid item xs={12} px={{ xs: "8%", md: 0 }}>
          <Line />
          <Typography variant="h2" color="white">
            Powering DOOH: location-based advertising
          </Typography>
          <Typography variant="body1" color="white" marginX="auto">
            We deliver tailored ads that resonate with consumers at multiple
            touchpoints throughout their day, all in real-time. Transform your
            advertising from simply being seen to creating impactful brand
            experiences.
          </Typography>
        </Grid>
        <LottieAnimation />
      </Section>
    </Box>
  );
}

export default Section4;
