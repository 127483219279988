import React, { useEffect } from 'react';
import NavBar from '../../components/NavBar/navbar';
import Footer from '../../components/Footer/footer';
import HeroSection from './vendorsHero';
import Section1 from './vendorsSection1';
import Section2 from './vendorsSection2';
import Section3 from './vendorsSection3';
import Section4 from './vendorsSection4';
import Section5 from './vendorsSection5';
import Section6 from './vendorsSection6';

function Vendors() {
  useEffect(() => {
    document.title = "Portl Media | Vendors";
  }, []);

  return (
    <>
    <NavBar page='vendors'/>
    <HeroSection/>
    <Section1/>
    <Section2/>
    <Section3/>
    <Section4/>
    <Section5/>
    <Section6/>
    <Footer/>
    </>
  );
}

export default Vendors;
