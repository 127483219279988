import React from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

export default function AdsFAQ() {
  return (
    <>
      {/* FAQ 1 */}
      <Accordion
        sx={{
          boxShadow: "none",
          background: "transparent",
        }}
      >
        <AccordionSummary>
          <Typography
            variant="body1"
            fontWeight="bold"
            textAlign={"left"}
            marginY={2}
            marginX={{ xs: "8%", md: 0 }}
          >
            How does Portl enable better targeting for my ads?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="body1"
            textAlign={"left"}
            maxWidth={"100%"}
            marginBottom={0}
            marginX={{ xs: "8%", md: 0 }}
          >
            Portl uses advanced data analytics and AI technology to analyze and
            predict audience behavior. This allows us to deliver your ads to the
            right people, at the right time, in the right place, increasing your
            ad's effectiveness and ROI.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* FAQ 2 */}
      <Accordion
        sx={{
          boxShadow: "none",
          background: "transparent",
        }}
      >
        <AccordionSummary>
          <Typography
            variant="body1"
            fontWeight="bold"
            textAlign={"left"}
            marginY={2}
            marginX={{ xs: "8%", md: 0 }}
          >
            How does Portl provide a cost-effective advertising solution?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="body1"
            textAlign={"left"}
            maxWidth={"100%"}
            marginBottom={0}
            marginX={{ xs: "8%", md: 0 }}
          >
            By maximizing ad space utilization across diverse venues, we offer
            more opportunities for exposure. You pay only for the actual
            impressions delivered, ensuring efficient use of your ad budget.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* FAQ 3 */}
      <Accordion
        sx={{
          boxShadow: "none",
          background: "transparent",
        }}
      >
        <AccordionSummary>
          <Typography
            variant="body1"
            fontWeight="bold"
            textAlign={"left"}
            marginY={2}
            marginX={{ xs: "8%", md: 0 }}
          >
            How does Portl solve the issue of fragmentation in the DOOH
            advertising industry?{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="body1"
            textAlign={"left"}
            maxWidth={"100%"}
            marginBottom={0}
            marginX={{ xs: "8%", md: 0 }}
          >
            Portl has unified diverse venues into a single, easy-to-access
            network. Advertisers can reach audiences across multiple venues
            through a single platform, simplifying the process and increasing
            your ad's reach.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* FAQ 4 */}
      <Accordion
        sx={{
          boxShadow: "none",
          background: "transparent",
        }}
      >
        <AccordionSummary>
          <Typography
            variant="body1"
            fontWeight="bold"
            textAlign={"left"}
            marginY={2}
            marginX={{ xs: "8%", md: 0 }}
          >
            How can I measure the effectiveness of my ads on Portl?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="body1"
            textAlign={"left"}
            maxWidth={"100%"}
            marginBottom={0}
            marginX={{ xs: "8%", md: 0 }}
          >
            Our platform provides detailed analytics on ad impressions, viewer
            engagement, and more, allowing you to track and optimize your ad
            performance.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
