import React from "react";
import { Grid, Typography } from "@mui/material";
import { theme } from "../../theme";
import Line from "../../components/Line/line";
import { Button } from "../../components/Button/button";
import Section from "../../components/Section/section";

function Section2() {
  return (
    <Section bg={theme.colours.lightGrey}>
      <Grid
        item
        xs={12}
        display="flex"
        alignItems="center"
        md={6}
        pl={{ xs: "8%", md: "0" }}
        pr={{ xs: "8%", md: "40px" }}
        mb={{ xs: "8%", md: "0" }}
      >
        <img
          src="./vendors_circles.webp"
          style={{ width: "100%", maxHeight: "100%" }}
        />
      </Grid>
      <Grid
        item
        direction="column"
        xs={12}
        md={6}
        pl={{ xs: "8%", md: "40px" }}
        pr={{ xs: "8%", md: "0" }}
        sx={{ textAlign: { xs: "center", md: "left" } }}
      >
        <Line />
        <Typography variant="h2">How it works</Typography>

        {/* List element 1 */}
        <Grid container>
          {/* Icon */}
          <Grid
            item
            xs={3}
            sx={{
              background: theme.colours.darkNavy,
              borderRadius: "50px",
              mr: "25px",
              textAlign: "center",
              paddingY: "5px",
              pl: "2px",
              maxWidth: "40px !important",
              height: "40px",
            }}
          >
            <Typography variant="button" fontSize="18px">
              <b>1</b>
            </Typography>
          </Grid>
          {/* Text */}
          <Grid item xs={9} textAlign="left">
            <Typography variant="body1">
              <b>Join our network</b>
              <br />
              Get onboard with Portl and make your venue a part of our expansive
              network. It's as simple as saying 'yes'!
            </Typography>
          </Grid>
          {/* Divider line */}
          <Grid item xs={12}>
            <hr
              style={{
                height: "3px",
                width: "100%",
                backgroundColor: "#e9e9e9",
                borderWidth: "0",
                marginBottom: theme.paddings.text,
                display: "inline-flex",
              }}
            />
          </Grid>
        </Grid>

        {/* List element 2 */}
        <Grid container>
          {/* Icon */}
          <Grid
            item
            xs={3}
            sx={{
              background: theme.colours.darkNavy,
              borderRadius: "50px",
              mr: "25px",
              textAlign: "center",
              paddingY: "5px",
              pl: "2px",
              maxWidth: "40px !important",
              height: "40px",
            }}
          >
            <Typography variant="button" fontSize="18px">
              <b>2</b>
            </Typography>
          </Grid>
          {/* Text */}
          <Grid item xs={9} textAlign="left">
            <Typography variant="body1">
              <b>Integration made easy</b>
              <br />
              We seamlessly integrate you into our network. It's a hassle-free
              process designed to get you live in no time.
            </Typography>
          </Grid>
          {/* Divider line */}
          <Grid item xs={12}>
            <hr
              style={{
                height: "3px",
                width: "100%",
                backgroundColor: "#e9e9e9",
                borderWidth: "0",
                marginBottom: theme.paddings.text,
                display: "inline-flex",
              }}
            />
          </Grid>
        </Grid>

        {/* List element 3 */}
        <Grid container>
          {/* Icon */}
          <Grid
            item
            xs={3}
            sx={{
              background: theme.colours.darkNavy,
              borderRadius: "50px",
              mr: "25px",
              textAlign: "center",
              paddingY: "5px",
              pl: "2px",
              maxWidth: "40px !important",
              height: "40px",
            }}
          >
            <Typography variant="button" fontSize="18px">
              <b>3</b>
            </Typography>
          </Grid>
          {/* Text */}
          <Grid item xs={9} textAlign="left">
            <Typography variant="body1">
              <b>Start earning</b>
              <br />
              Watch your venue thrive as we optimize your fill rates and unlock
              new revenue potential. You sit back, we deliver results.
            </Typography>
          </Grid>
          {/* Divider line */}
          <Grid item xs={12}>
            <hr
              style={{
                height: "3px",
                width: "100%",
                backgroundColor: "#e9e9e9",
                borderWidth: "0",
                marginBottom: theme.paddings.text,
                display: "inline-flex",
              }}
            />
          </Grid>
        </Grid>

        <Button href="/vendors-signup">Try it out</Button>
      </Grid>
    </Section>
  );
}

export default Section2;
