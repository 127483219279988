import React, { useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { theme } from "../../theme";
import Line from "../../components/Line/line";
import Section from "../../components/Section/section";
import Card from "../../components/Card/card";
import { styled } from "styled-components";
import ButtonModal from "../../components/ButtonModal/buttonModal";
import { Button } from "../../components/Button/button";

///////////////// STYLED COMPONENTS ////////////////
const Icon = styled.img`
  text-align: center;
  height: 55px;
  margin-bottom: ${(props) => props.theme.paddings.text};
`;

//////////////////// FUNCTIONS ////////////////////
function Section5() {
  // currVendor options that control image displayed:
  // rideshare (default), car-top-screens, urban-pannels, digital-billboards, ev-chargers
  const [currVendor, setCurrVendor] = useState("rideshare");

  return (
    <>
      <Box id="our-inventory" sx={{ height: { xs: "200%", md: "150%" } }}>
        <img
          src="./city_gradient.webp"
          style={{
            width: "100%",
            height: "inherit",
            objectFit: "cover",
            position: "absolute",
            zIndex: 1,
          }}
        />
      </Box>
      <Section bg={"transparent"}>
        <Grid
          item
          xs={12}
          px={{ xs: "8%", lg: "0" }}
          textAlign={"center"}
          position="relative"
          zIndex={1}
        >
          <Line />
          <Typography variant="h2" color="white">
            Connected screens across North America
          </Typography>
          <Typography variant="body1" color="white" marginX="auto">
            Our network extends over 50 cities across Canada and the U.S. Have a
            venue type that's not listed? We are happy to work with you. Get in
            contact with us now!
          </Typography>
          <Button href="/vendors-signup">Join our network</Button>
        </Grid>
        <Grid item xs={12} md={6} pt={{ xs: "0", md: "15px" }}>
          <img
            src={"./" + currVendor + ".webp"}
            style={{ position: "relative", zIndex: "2", width: "80%" }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ButtonModal value={currVendor} setValue={setCurrVendor} />
        </Grid>
      </Section>
    </>
  );
}

export default Section5;
