import React from "react";
import { Box } from "@mui/material";
import { darken } from "@mui/material/styles";
import { theme } from "../../theme";
import { Button } from "../Button/button";

interface ButtonModalProps {
    value: string; //controls button styling
    setValue: (value: string) => void;
};

export default function ButtonModal({ value, setValue }: ButtonModalProps) {
    return (
    <Box display='inline-flex' flexDirection='column' sx={{ position: 'relative', zIndex:'1', background: theme.colours.navyBlue, 
    border:'0px solid', borderRadius: '40px', margin: '30px', justifyContent: 'center', textAlign:'center', padding: '10px'}}>
        <Button kind='navy' sx={{mb:'5px', 
        backgroundColor: value === 'rideshare' ? darken(theme.colours.navyBlue, 0.2) : '',
        borderColor: value === 'rideshare' ? theme.colours.portlBlue : 'transparent'}} 
        onClick={() => setValue('rideshare')}>Rideshare</Button>

        <Button kind='navy' sx={{mb:'5px', 
        backgroundColor: value === 'car-top-screens' ? darken(theme.colours.navyBlue, 0.2) : '',
        borderColor: value === 'car-top-screens' ? theme.colours.portlBlue : 'transparent'}} 
        onClick={() => setValue('car-top-screens')}>Car-top screens</Button>

        <Button kind='navy' sx={{mb:'5px',
        backgroundColor: value === 'urban-pannels' ? darken(theme.colours.navyBlue, 0.2) : '',
        borderColor: value === 'urban-pannels' ? theme.colours.portlBlue : 'transparent'}} 
        onClick={() => setValue('urban-pannels')}>Urban panels</Button>

        <Button kind='navy' sx={{mb:'5px',
        backgroundColor: value === 'digital-billboards' ? darken(theme.colours.navyBlue, 0.2) : '',
        borderColor: value === 'digital-billboards' ? theme.colours.portlBlue : 'transparent'}} 
        onClick={() => setValue('digital-billboards')}>Digital billboards</Button>
        
        <Button kind='navy' sx={{mb:'5px',
        backgroundColor: value === 'ev-chargers' ? darken(theme.colours.navyBlue, 0.2) : '',
        borderColor: value === 'ev-chargers' ? theme.colours.portlBlue : 'transparent'}} 
        onClick={() => setValue('ev-chargers')}>EV chargers</Button>

        <Button kind='navy' sx={{mb:'5px',
        backgroundColor: value === 'more' ? darken(theme.colours.navyBlue, 0.2) : '',
        borderColor: value === 'more' ? theme.colours.portlBlue : 'transparent'}} 
        onClick={() => setValue('more')}>+ More</Button>
    </Box>
    );
};