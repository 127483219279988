import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import Line from "../../components/Line/line";
import Section from "../../components/Section/section";
import Wave from "../../components/Wave/wave";

function Section4() {
  return (
    <>
      <Box
        sx={{
          marginTop: { xs: "-50px", md: "-150px" },
          marginBottom: "-10px",
          width: "100%",
          position: "relative",
          zIndex: 2,
        }}
      >
        <Wave colour={"#bce6f6"} />
      </Box>
      <Section bg="#bce6f6" pt={false}>
        <Grid
          item
          direction="column"
          xs={12}
          md={6}
          pr="8%"
          pl={{ xs: "8%", lg: "0" }}
          sx={{ textAlign: { xs: "center", md: "left" } }}
        >
          <Line />
          <Typography variant="h2">Why choose Portl?</Typography>
          <Typography variant="body1">
            We're not just an advertising platform, we're the catalyst for your
            growth. Leveraging our dual-strategy model that blends scalability
            with flexibility, we're transforming the DOOH space. Proprietary
            technology? Check. Exclusive partnerships? You bet. With Portl,
            you're not just choosing a service, you're joining the forefront of
            advertising innovation.
          </Typography>
        </Grid>
        <Grid display="flex" alignItems="end" item xs={12} md={6} p={4}>
          <img src="portl_network.webp" style={{ width: "100%" }} />
        </Grid>
      </Section>
    </>
  );
}

export default Section4;
