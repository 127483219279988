import React from "react";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
import { Grid, Typography, ThemeProvider, Box } from "@mui/material";
import textTheme, { theme } from "../../theme";
import Logo from "../Logo/logo";

///////////////// STYLED COMPONENTS ////////////////
const FooterLink = styled(Link)`
  color: ${(props) => props.theme.colours.white};
  width: 100%;
  white-space: nowrap;
  text-decoration: none;
  display: flex;
  justify-content: left;
  align-items: left;
  margin-bottom: 5px;
`;

//////////////////// FUNCTIONS ////////////////////
function Footer() {
  return (
    <ThemeProvider theme={textTheme}>
      {/* sets background color beyond maxWidth */}
      <Box
        display="flex"
        position="relative"
        zIndex={2}
        margin="auto"
        width="100%"
        style={{ background: theme.colours.portlBlue }}
      >
        {/* sets maxWidth */}
        <Box
          display="flex"
          flexDirection="column"
          margin="auto"
          maxWidth="1080px"
          pb={{ xs: 5, md: 0 }}
        >
          {/* sets padding/margin/alignment */}
          <Grid container py={5} pl={{ xs: "8%", md: "0" }} columnSpacing={10}>
            <Grid item xs={12} md={3}>
              <FooterLink to="/home" style={{ marginLeft: "-20px" }}>
                <Logo colour={theme.colours.white} size="35px" />
              </FooterLink>
            </Grid>
            <Grid item xs={6} md={3} pt={{ xs: "8%", md: "0" }}>
              <Typography variant="button">Website</Typography>
              <FooterLink
                to="https://drivers.portlmedia.com/login"
                target="_blank"
              >
                Existing partners
              </FooterLink>
              <FooterLink to="/ads">Advertisers</FooterLink>
              <FooterLink to="/vendors">Vendors</FooterLink>
            </Grid>
            <Grid item xs={6} md={3} pt={{ xs: "8%", md: "0" }}>
              <Typography variant="button">Company</Typography>
              <FooterLink
                to="https://wellfound.com/company/portl-media-1/jobs"
                target="_blank"
              >
                Careers
              </FooterLink>
            </Grid>
            <Grid item xs={6} md={3} pt={{ xs: "8%", md: "0" }}>
              <Typography variant="button">Social</Typography>
              <FooterLink
                to="https://www.facebook.com/portlmedia/"
                target="_blank"
              >
                Facebook
              </FooterLink>
              <FooterLink
                to="https://www.instagram.com/portlmedia/?hl=en"
                target="_blank"
              >
                Instagram
              </FooterLink>
              <FooterLink
                to="https://ca.linkedin.com/company/portl-media"
                target="_blank"
              >
                LinkedIn
              </FooterLink>
            </Grid>
          </Grid>
          <Typography variant="body1" color="white" textAlign="center">
            © 2023 Portl Media. All rights reserved.
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Footer;
