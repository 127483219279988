import React from "react";
import Lottie from "react-lottie";
// import animationData from "../../lotties/ads_animation.json";
import animationData from "../../lotties/lottie_new.json";
import { Box } from "@mui/material";
import "./lottieAnimation.css";

export default function LottieAnimation() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'none'
    },
    class: "lottieContainer",
  };

  return (
    <Box marginTop={{ xs: "20px", md: "0" }} marginBottom={{ xs: "-30px", md: "-80px" }} className="lottieContainer">
      <Lottie options={defaultOptions} height="100%" width="100%"/>
    </Box>
  );
}
