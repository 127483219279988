import React from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

export default function VendorsFAQ() {
  return (
    <>
      {/* FAQ 1 */}
      <Accordion
        sx={{
          boxShadow: "none",
          background: "transparent",
        }}
      >
        <AccordionSummary>
          <Typography
            variant="body1"
            fontWeight="bold"
            textAlign={"left"}
            marginY={2}
            marginX={{ xs: "8%", md: 0 }}
          >
            How does Portl help increase my venue's profitability?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="body1"
            textAlign={"left"}
            maxWidth={"100%"}
            marginBottom={0}
            marginX={{ xs: "8%", md: 0 }}
          >
            Portl works to maximize your venue's ad space utilization. By
            connecting you with a broad network of advertisers, we ensure your
            ad space is always in use, maximizing your revenue.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* FAQ 2 */}
      <Accordion
        sx={{
          boxShadow: "none",
          background: "transparent",
        }}
      >
        <AccordionSummary>
          <Typography
            variant="body1"
            fontWeight="bold"
            textAlign={"left"}
            marginY={2}
            marginX={{ xs: "8%", md: 0 }}
          >
            How easy is it to join the Portl network?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="body1"
            textAlign={"left"}
            maxWidth={"100%"}
            marginBottom={0}
            marginX={{ xs: "8%", md: 0 }}
          >
            Joining the Portl network is straightforward and stress-free. We'll
            work with you to integrate your digital signage into our network and
            get you up and running.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* FAQ 3 */}
      <Accordion
        sx={{
          boxShadow: "none",
          background: "transparent",
        }}
      >
        <AccordionSummary>
          <Typography
            variant="body1"
            fontWeight="bold"
            textAlign={"left"}
            marginY={2}
            marginX={{ xs: "8%", md: 0 }}
          >
            What type of support does Portl provide to venue owners?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="body1"
            textAlign={"left"}
            maxWidth={"100%"}
            marginBottom={0}
            marginX={{ xs: "8%", md: 0 }}
          >
            Our team provides full support from the integration process through
            to the ongoing operation. We are committed to making this
            partnership beneficial and hassle-free for you.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* FAQ 4 */}
      <Accordion
        sx={{
          boxShadow: "none",
          background: "transparent",
        }}
      >
        <AccordionSummary>
          <Typography
            variant="body1"
            fontWeight="bold"
            textAlign={"left"}
            marginY={2}
            marginX={{ xs: "8%", md: 0 }}
          >
            If Portl isn't a media agency or DSP/SSP, what is it?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="body1"
            textAlign={"left"}
            maxWidth={"100%"}
            marginBottom={0}
            marginX={{ xs: "8%", md: 0 }}
          >
            Portl is a solution provider that uses innovative technology to make
            DOOH advertising more efficient and profitable. We connect venue
            owners with advertisers, maximizing ad space utilization and
            unlocking new revenue streams.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
