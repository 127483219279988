import React from "react";
import { Grid, Box, Typography, Link } from "@mui/material";
import { theme } from "../../theme";
import Line from "../../components/Line/line";
import Section from "../../components/Section/section";
import { Button } from "../../components/Button/button";
import AdsFAQ from "../../components/FAQ/adsFAQ";

function Section5() {
  return (
    <Section bg={theme.colours.white} zIndex={2}>
      <Grid
        item
        xs={12}
        sx={{ display: "inline-box", flexDirection: "column" }}
      >
        <Line />
        <Typography
          variant="body2"
          color={theme.colours.darkNavy}
          marginX="auto"
        >
          Learn more about us
        </Typography>
        <AdsFAQ />
      </Grid>

      <Grid item mt="30px" mr={{ xs: 0, md: "30px" }}>
        <Button href="/ads-signup">Get in contact</Button>
      </Grid>
      <Grid item mt="30px">
        <Link
          style={{ textDecoration: "none" }}
          href="https://go.portlmedia.com/resources"
          target="_blank"
        >
          <Button kind="outline">View resources ↗</Button>
        </Link>
      </Grid>
    </Section>
  );
}

export default Section5;
