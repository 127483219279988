import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import { theme } from "../../theme";
import styled from "styled-components";
import Line from "../../components/Line/line";
import Section from "../../components/Section/section";
import Card from "../../components/Card/card";

///////////////// STYLED COMPONENTS ////////////////
const Icon = styled.img`
  text-align: center;
  height: 55px;
  margin-bottom: ${(props) => props.theme.paddings.text};
`;

//////////////////// FUNCTIONS ////////////////////
function Section3() {
  return (
    <>
      <Box id="what-we-do" />
      <Section
        bg="linear-gradient(180deg, rgba(15,90,128,1) 0%, rgba(0,106,176,1) 100%)"
        pb={true}
      >
        <Grid item xs={12} px={{ xs: "8%", lg: "0" }}>
          <Line />
          <Typography variant="h2" color="white">
            Pioneering innovation in DOOH
          </Typography>
          <Typography variant="body1" color="white" marginX={"auto"}>
            We're reshaping the DOOH landscape by creating cutting-edge
            solutions that are flexible, scalable, and forward-thinking. Our
            strategy is threefold:
          </Typography>
        </Grid>

        {/* Card 1 */}
        <Grid container>
          <Grid item xs={12} md={4}>
            <Card
              bg={theme.colours.navyBlue}
              border={theme.colours.portlBlue}
              minHeight="280px"
            >
              <Icon src="./icons/empower.svg" />
              <Typography variant="h3">Empower advertisers</Typography>
              <Typography variant="body1" color="white" mb="0">
                We offer a streamlined path for precise, efficient, and
                far-reaching campaigns.
              </Typography>
            </Card>
          </Grid>

          {/* Card 2 */}
          <Grid item xs={12} md={4}>
            <Card
              bg={theme.colours.navyBlue}
              border={theme.colours.portlBlue}
              minHeight="280px"
            >
              <Icon src="./icons/serve.svg" />
              <Typography variant="h3">Elevate venue owners</Typography>
              <Typography variant="body1" color="white" mb="0">
                We transform your location into a revenue powerhouse with
                untapped profit avenues.
              </Typography>
            </Card>
          </Grid>

          {/* Card 3 */}
          <Grid item xs={12} md={4}>
            <Card
              bg={theme.colours.navyBlue}
              border={theme.colours.portlBlue}
              minHeight="280px"
            >
              <Icon src="./icons/foster.svg" />
              <Typography variant="h3">Enhance consumer experience</Typography>
              <Typography variant="body1" color="white" mb="0">
                We curate memorable experiences with relevant, engaging content.
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Section>
    </>
  );
}

export default Section3;
