import React from "react";
import { Grid, Typography } from "@mui/material";
import { theme } from "../../theme";
import Line from "../../components/Line/line";
import Section from "../../components/Section/section";
import { Button } from "../../components/Button/button";

function Section4() {
  return (
    <Section bg="#bce6f6">
      <Grid item xs={12} px={{ xs: "8%", md: 0 }}>
        <Line />
        <Typography variant="h2">We are Portl.</Typography>
        <Typography variant="body1" marginX="auto">
          An innovative solution provider transforming the way venue owners
          monetize their advertising space. We're here to shake things up and
          drive efficiency and profitability in OOH advertising.
        </Typography>
        <Button href="/vendors-signup">Get in touch</Button>
      </Grid>
    </Section>
  );
}

export default Section4;
