import { createTheme, responsiveFontSizes } from "@mui/material";

export const theme = {
    colours: {
    portlBlue: '#1FB6FF',
    deepBlue: '#169AFF',
    navyBlue: '#006ABB',
    coral: '#FF6473',
    yellow: '#FFDB58',
    purple: '#EB67FF',
    white: '#FFFFFF',
    lightGrey: '#f4f4f4',
    grey: '#D6D6D6',
    darkNavy: '#0F5A80',
    black: '#222222',
    },
    fontSizes: {
        paragraph: '16px',
        title: '22px',
        card: '26px',
        subheading: '40px',
        heading: '56px',
    },
    fontFamily: 'Montserrat',
    paddings: {
        page: '15vw',
        text: '25px',
        section: '100px',
        heading: '10%',
    }
};

let textTheme = createTheme({
    typography: {
      fontFamily: theme.fontFamily,
      h1: {
        color: theme.colours.darkNavy,
        fontWeight: 'bold',
        fontSize: theme.fontSizes.heading,
        marginBottom: theme.paddings.text,
      },
      h2: {
        color: theme.colours.darkNavy,
        fontWeight: 'bold',
        fontSize: theme.fontSizes.subheading,
        marginBottom: theme.paddings.text,
      },
      h3: { // card title
        color: theme.colours.white,
        fontWeight: '600',
        fontSize: theme.fontSizes.card,
        marginBottom: theme.paddings.text,
      },
      body1: {
        color: theme.colours.black,
        marginBottom: theme.paddings.text,
        maxWidth: '700px',
      },
      body2: { // past partners subheading
        color: theme.colours.black,
        fontWeight: 'bold',
        fontSize: theme.fontSizes.card,
        marginBottom: theme.paddings.text,
        maxWidth: '700px',
      },
      button: {
        color: 'white',
        fontWeight: '600',
        fontSize: theme.fontSizes.paragraph,
        mb: '5px',
        textTransform: 'none',
      }
    },
});

textTheme = responsiveFontSizes(textTheme);

export default textTheme;