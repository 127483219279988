import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import { theme } from "../../theme";
import Line from "../../components/Line/line";
import { Button } from "../../components/Button/button";
import Section from "../../components/Section/section";
import Wave from "../../components/Wave/wave";

function Section1() {
  return (
    <>
      <Box sx={{ marginBottom: "-10px", width: "100%" }}>
        <Wave colour={theme.colours.white} />
      </Box>
      <Section bg={theme.colours.white} pt={false}>
        <Grid
          item
          direction="column"
          xs={12}
          md={6}
          pr="8%"
          pl={{ xs: "8%", lg: "0" }}
          sx={{ textAlign: { xs: "center", md: "left" } }}
        >
          <Line />
          <Typography variant="h2">Elevate your advertising</Typography>
          <Typography variant="body1">
            Multiply your reach, supercharge engagement, and optimize your ad
            strategy with our cutting-edge DOOH solutions. Backed by superior
            data analytics, we're here to help you break new advertising ground.
          </Typography>
          <Button href="/ads">Learn more</Button>
        </Grid>
        <Grid
          display="flex"
          alignItems="end"
          item
          xs={12}
          md={6}
          px={{ xs: "8%", md: "0" }}
          mt={{ xs: "8%", md: "0" }}
        >
          <img src="./rideshare_car.webp" style={{ width: "100%" }} />
        </Grid>
      </Section>
    </>
  );
}

export default Section1;
