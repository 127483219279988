import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import "./App.css";
import Home from "./pages/Home/home";
import Vendors from "./pages/Vendors/vendors";
import Ads from "./pages/Ads/ads";
import ScrollToTop from "./components/ScrollToTop/scrollToTop";
import AdsSignUp from "./pages/AdsSignUp/adsSignUp";
import VendorsSignUp from "./pages/VendorsSignUp/vendorsSignUp";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="vendors" element={<Vendors />} />
          <Route path="ads" element={<Ads />} />
          <Route path="ads-signup" element={<AdsSignUp />} />
          <Route path="contact" element={<AdsSignUp />} />
          <Route path="vendors-signup" element={<VendorsSignUp />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
