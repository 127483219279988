import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import { theme } from "../../theme";
import Line from "../../components/Line/line";
import Section from "../../components/Section/section";
import Card from "../../components/Card/card";
import { styled } from "styled-components";
import Wave from "../../components/Wave/wave";

///////////////// STYLED COMPONENTS ////////////////
const Icon = styled.img`
  text-align: center;
  height: 55px;
  margin-bottom: ${(props) => props.theme.paddings.text};
`;

//////////////////// FUNCTIONS ////////////////////
function Section1() {
  return (
    <>
      <Box
        sx={{
          marginTop: { xs: "-100px", md: "0" },
          marginBottom: "-10px",
          width: "100%",
        }}
      >
        <Wave colour={theme.colours.darkNavy} />
      </Box>
      <Section bg={theme.colours.darkNavy} pt={false}>
        <Grid item xs={12} px={{ xs: "8%", md: 0 }}>
          <Line />
          <Typography variant="h2" color="white">
            Why partner with us?
          </Typography>
          <Typography variant="body1" color="white" marginX="auto">
            By joining Portl, you become part of a wider network of advertisers
            and venues shaping the future of DOOH advertising. Not only does
            Portl help give your venue increased exposure, but we also ensure a
            steady stream of advertising demand for your
            screens.
          </Typography>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={4}>
            <Card bg={theme.colours.navyBlue} border={theme.colours.portlBlue}>
              <Icon src="./icons/optimized.svg" />
              <Typography variant="h3">Maximize revenue</Typography>
              <Typography variant="body1" color="white" mb="0">
                Optimize fill rates and earn more from your existing space.
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card bg={theme.colours.navyBlue} border={theme.colours.portlBlue}>
              <Icon src="./icons/effortless.svg" />
              <Typography variant="h3">Join with ease</Typography>
              <Typography variant="body1" color="white" mb="0">
                We handle all the details from setup to management.
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card bg={theme.colours.navyBlue} border={theme.colours.portlBlue}>
              <Icon src="./icons/value.svg" />
              <Typography variant="h3">Generate profit</Typography>
              <Typography variant="body1" color="white" mb="0">
                Transform any venue into a lucrative ad space.
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Section>
    </>
  );
}

export default Section1;
