import React from "react";
import styled from "styled-components";
import { Typography, Grid, Box } from "@mui/material";
import { Button } from "../../components/Button/button";
import Section from "../../components/Section/section";

//////////////////// FUNCTIONS ////////////////////

function HeroSection() {
  return (
    <>
      {/* Hero image for widescreen */}
      <Grid container position="absolute">
        <Grid item xs={12} md={6}></Grid>
        <Box
          component={Grid}
          item
          sm={12}
          md={6}
          display={{ xs: "none", md: "block" }}
        >
          <img src="./vendors_hero_bg.webp" style={{ width: "100%" }} />
        </Box>
      </Grid>
      <Section bg="transparent">
        <Grid
          item
          xs={12}
          md={6}
          pt={{ xs: 15, md: 18 }}
          pr={{ xs: "40px", md: "80px" }}
          pl={{ xs: "8%", md: 0 }}
          textAlign="left"
          sx={{ background: "white" }}
        >
          <Typography variant="h1">Earn more as a venue owner</Typography>
          <Typography variant="body1">
            Unlock new revenue streams with Portl. Regardless of your current
            screen infrastructure, we've got you covered.
          </Typography>
          <Button href="/vendors-signup">Sign up to be a vendor</Button>
        </Grid>
        {/* Hero image for mobile */}
        <Box
          component={Grid}
          item
          sm={12}
          md={6}
          display={{ xs: "block", md: "none" }}
          mt={8}
        >
          <img src="./vendors_hero_bg.webp" style={{ width: "100%" }} />
        </Box>
        {/* Aligns text box to half screen on widescreen */}
        <Box
          component={Grid}
          item
          sm={12}
          md={6}
          display={{ xs: "none", md: "block" }}
        ></Box>
      </Section>
    </>
  );
}

export default HeroSection;
