import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import { theme } from "../../theme";
import Line from "../../components/Line/line";
import { Button } from "../../components/Button/button";
import Section from "../../components/Section/section";

function Section2() {
  return (
    <Section bg={theme.colours.lightGrey}>
      <Grid
        display="flex"
        alignItems="center"
        item
        xs={12}
        md={6}
        px={{ xs: 2, md: 0 }}
      >
        <img src="black_car.webp" style={{ width: "100%" }} />
      </Grid>
      <Grid
        item
        direction="column"
        xs={12}
        md={6}
        pl="8%"
        pr={{ xs: "8%", lg: "0" }}
        pt="30px"
        sx={{ textAlign: { xs: "center", md: "left" } }}
      >
        <Line />
        <Typography variant="h2">
          Join our network: your venue, your profit
        </Typography>
        <Typography variant="body1">
          Whether you have an existing digital network or unmonetized real
          estate, Portl is your partner. Seamlessly integrate our solutions to
          unlock new revenue streams and enhance consumer engagement. Stand out
          from the crowd and amplify your profits with Portl.
        </Typography>
        <Button href="/vendors">Learn more</Button>
      </Grid>
    </Section>
  );
}

export default Section2;
