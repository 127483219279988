import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import { theme } from "../../theme";
import Line from "../../components/Line/line";
import Section from "../../components/Section/section";
import Wave from "../../components/Wave/wave";
import { Button } from "../../components/Button/button";
import VendorsFAQ from "../../components/FAQ/vendorsFAQ";

function Section6() {
  return (
    <>
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
          marginTop: { xs: "-50px", md: "-80px" },
          marginBottom: "-10px",
          width: "100%",
        }}
      >
        <Wave colour={theme.colours.white} />
      </Box>
      <Section bg={theme.colours.white} pt={false} zIndex={2}>
        <Grid
          item
          xs={12}
          sx={{ display: "inline-box", flexDirection: "column" }}
        >
          <Line />
          <Typography
            variant="body2"
            color={theme.colours.darkNavy}
            marginX="auto"
          >
            Learn more about us
          </Typography>
          <VendorsFAQ />
        </Grid>
        <Grid item mt="30px">
          <Button href="/vendors-signup">Sign up to be a vendor</Button>
        </Grid>
      </Section>
    </>
  );
}

export default Section6;
