import React, { useEffect } from "react";

const AdsForm = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @ts-ignore
      if (window.hbspt) {
        // @ts-ignore
        window.hbspt.forms.create({
          region: "na1",
          portalId: "2867024",
          formId: "e5ef4aef-f9bb-4a79-8320-74057f3aca25",
          target: "#hubspotForm",
          cssClass: "form",
        });
      }
    });
  }, []);

  return <div style={{textAlign:"left"}} id="hubspotForm" />;
};

export default AdsForm;
