import React from 'react';
import { theme } from "../../theme";
import { Box } from '@mui/material';
  
interface WaveProps {
    colour?: string;
  }
  
function Wave({ colour }: WaveProps) {
    return(
        <svg style={{position:'relative', zIndex: 'inherit', width:'inherit', height:'inherit'}} xmlns="http://www.w3.org/2000/svg" width="1919.728" height="226.801" viewBox="0 0 1919.728 226.801">
            <path id="Path_2539" data-name="Path 2539" d="M-307,2381.953s416.7-150.3,986.394,0,933.333,0,933.333,0v160H-307Z" transform="translate(307 -2315.151)" fill={colour}/>
        </svg>
    );
};

export default Wave;