import React, { useEffect } from "react";
import NavBar from "../../components/NavBar/navbar";
import HeroSection from "./homeHero";
import Section1 from "./homeSection1";
import Section2 from "./homeSection2";
import Section3 from "./homeSection3";
import Section4 from "./homeSection4";
import Section5 from "./homeSection5";
import Footer from "../../components/Footer/footer";
import Section6 from "./homeSection6";

function Home() {
  useEffect(() => {
    document.title = "Portl Media";
  }, []);

  return (
    <>
      <NavBar page="home" />
      <HeroSection />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Footer />
    </>
  );
}

export default Home;
