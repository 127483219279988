import React from "react";
import { Grid, Typography } from "@mui/material";
import { theme } from "../../theme";
import Line from "../../components/Line/line";
import { Button } from "../../components/Button/button";
import Section from "../../components/Section/section";

function Section3() {
  return (
    <Section bg={theme.colours.white}>
      <Grid
        item
        direction="column"
        xs={12}
        md={6}
        pr="8%"
        pl={{ xs: "8%", lg: "0" }}
        sx={{ textAlign: { xs: "center", md: "left" } }}
      >
        <Line />
        <Typography variant="h2">What we are not</Typography>
        {/* <Typography variant="body1">
          <b>We are not a media agency, DSP/SSP, or traditional advertising
          platform. </b>We are an innovative solution provider that unlocks new
          revenue streams for venue owners by optimizing ad space utilization.
          Our mission is to make OOH advertising more efficient and profitable
          for everyone involved.
        </Typography> */}

        {/* List element 1 */}
        <Grid container>
          {/* Icon */}
          <Grid
            item
            xs={3}
            sx={{
              background: theme.colours.coral,
              borderRadius: "50px",
              mr: "25px",
              textAlign: "center",
              paddingY: "5px",
              pl: "2px",
              maxWidth: "40px !important",
              height: "40px",
            }}
          >
            <Typography variant="button" fontSize="18px">
              <b>X</b>
            </Typography>
          </Grid>
          {/* Text */}
          <Grid item xs={9} textAlign="left">
            <Typography variant="body1">
              <b>Not just another media agency</b>
              <br />
              We don't simply connect advertisers to audiences. We're an
              innovative platform that helps venue owners optimize their
              advertising space and unlock new revenue streams.
            </Typography>
          </Grid>
          {/* Divider line */}
          <Grid item xs={12}>
            <hr
              style={{
                height: "3px",
                width: "100%",
                backgroundColor: theme.colours.lightGrey,
                borderWidth: "0",
                marginBottom: theme.paddings.text,
                display: "inline-flex",
              }}
            />
          </Grid>
        </Grid>

        {/* List element 2 */}
        <Grid container>
          {/* Icon */}
          <Grid
            item
            xs={3}
            sx={{
              background: theme.colours.coral,
              borderRadius: "50px",
              mr: "25px",
              textAlign: "center",
              paddingY: "5px",
              pl: "2px",
              maxWidth: "40px !important",
              height: "40px",
            }}
          >
            <Typography variant="button" fontSize="18px">
              <b>X</b>
            </Typography>
          </Grid>
          {/* Text */}
          <Grid item xs={9} textAlign="left">
            <Typography variant="body1">
              <b>Not a DSP/SSP</b>
              <br />
              Our model isn't confined to traditional programmatic buying and
              selling methods. We believe in a more holistic approach that
              benefits all stakeholders.
            </Typography>
          </Grid>
          {/* Divider line */}
          <Grid item xs={12}>
            <hr
              style={{
                height: "3px",
                width: "100%",
                backgroundColor: theme.colours.lightGrey,
                borderWidth: "0",
                marginBottom: theme.paddings.text,
                display: "inline-flex",
              }}
            />
          </Grid>
        </Grid>

        {/* List element 3 */}
        <Grid container>
          {/* Icon */}
          <Grid
            item
            xs={3}
            sx={{
              background: theme.colours.coral,
              borderRadius: "50px",
              mr: "25px",
              textAlign: "center",
              paddingY: "5px",
              pl: "2px",
              maxWidth: "40px !important",
              height: "40px",
            }}
          >
            <Typography variant="button" fontSize="18px">
              <b>X</b>
            </Typography>
          </Grid>
          {/* Text */}
          <Grid item xs={9} textAlign="left">
            <Typography variant="body1">
              <b>Not your usual advertising platform</b>
              <br />
              We're not about the status quo. We're about redefining Digital
              Out-Of-Home advertising to make it more efficient and profitable
              for everyone involved.
            </Typography>
          </Grid>
          {/* Divider line */}
          <Grid item xs={12}>
            <hr
              style={{
                height: "3px",
                width: "100%",
                backgroundColor: theme.colours.lightGrey,
                borderWidth: "0",
                marginBottom: theme.paddings.text,
                display: "inline-flex",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid display="flex" item xs={12} md={6} px={{ xs: "8%", md: "30px" }}>
        <img src="./city_screens.webp" style={{ width: "100%" }} />
      </Grid>
    </Section>
  );
}

export default Section3;
