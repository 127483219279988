import React from "react";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
import { Grid } from "@mui/material";

///////////////// STYLED COMPONENTS ////////////////
const NavLink = styled(Link)`
  color: ${(props) => props.theme.colours.black};
  width: 100%;
  font-weight: 600;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: ${(props) => props.theme.colours.lightGrey};
  }
`;

//////////////////// FUNCTIONS ////////////////////
function NavDropdown() {
  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        flexDirection: { xs: "column", md: "row" },
        mt: "65px",
        position: "fixed",
        zIndex: 3,
        boxShadow: "0 2px 10px 0 #00000024",
        background: "white",
      }}
    >
      <Grid container md={4} sx={{ border: 1, borderColor: "#e0e0e0" }}>
        <NavLink to="https://drivers.portlmedia.com/login">
          <img
            src="./tablet_icon.webp"
            style={{ height: "4.5rem", padding: "10px 30px 0 0" }}
          />
          Existing partners
        </NavLink>
      </Grid>
      <Grid container md={4} sx={{ border: 1, borderColor: "#e0e0e0" }}>
        <NavLink to="/ads">
          <img
            src="./pannels_icon.webp"
            style={{ height: "4.5rem", padding: "10px 30px 0 0" }}
          />
          Advertisers
        </NavLink>
      </Grid>
      <Grid container md={4} sx={{ border: 1, borderColor: "#e0e0e0" }}>
        <NavLink to="/vendors">
          <img
            src="./billboard_icon.webp"
            style={{ height: "4.5rem", padding: "10px 30px 0 0" }}
          />
          Vendors
        </NavLink>
      </Grid>
    </Grid>
  );
}

export default NavDropdown;
