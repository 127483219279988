import React, { useEffect } from "react";
import NavBar from "../../components/NavBar/navbar";
import Footer from "../../components/Footer/footer";
import { Box, Grid, Typography } from "@mui/material";
import Section from "../../components/Section/section";
import { theme } from "../../theme";
import AdsForm from "../../components/HubspotForm/adsForm";
import Wave from "../../components/Wave/wave";

function AdsSignUp() {
  useEffect(() => {
    document.title = "Portl Media | Ads Contact";
  }, []);

  return (
    <>
      <NavBar page="ads" />
      <Grid container sx={{ background: theme.colours.navyBlue }}>
        <Section bg="transparent" zIndex={2}>
          <Grid
            item
            xs={12}
            p={{ xs: "8%", md: "35px" }}
            m={{ xs: "8%", md: "30px" }}
            mt={{ xs: "25%", md: "60px" }}
            textAlign="center"
            sx={{
              background: "white",
              borderRadius: "20px",
              boxShadow: "0px 4px 30px rgba(38, 38, 38, 0.4)",
            }}
          >
            <img
              src="./adsSignup.webp"
              style={{
                width: "200px",
                marginTop: "-100px" ,
                marginBottom: "30px",
                textAlign: "center",
              }}
            />
            <Typography
              variant="h2"
              textAlign={"center"}
              mx="auto"
              sx={{ maxWidth: "600px" }}
            >
              Get in contact
            </Typography>
            <Typography
              variant="body1"
              textAlign={"center"}
              mx="auto"
              sx={{ maxWidth: "600px" }}
            >
              Experience targeted, efficient, and innovative digital out-of-home
              (DOOH) advertising.
            </Typography>
            <AdsForm />
          </Grid>
        </Section>
      </Grid>

      {/* wave in background */}
      <Box
        sx={{
          marginBottom: "-10px",
          width: "100%",
          position: "relative",
          marginTop: { xs: "-100%", md: "-30%" },
        }}
      >
        <Wave colour="#00589b" />
      </Box>
      <Box
        sx={{
          width: "100%",
          position: "relative",
          background: "#00589b",
          paddingBottom: { xs: "80%", md: "17%" },
        }}
      />
      <Footer />
    </>
  );
}

export default AdsSignUp;
