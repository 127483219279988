import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import { theme } from "../../theme";
import Line from "../../components/Line/line";
import Section from "../../components/Section/section";
import Card from "../../components/Card/card";
import { Button } from "../../components/Button/button";

//////////////////// FUNCTIONS ////////////////////
function Section3() {
  return (
    <Section bg={theme.colours.white}>
      <Grid item xs={12} px={{ xs: "8%", md: 0 }}>
        <Line />
        <Typography variant="h2">Key metrics</Typography>
      </Grid>

      {/* Card 1 */}
      <Grid item xs={12} md={3}>
        <Card
          bg={theme.colours.lightGrey}
          border={theme.colours.portlBlue}
          minHeight="130px"
        >
          <Typography variant="h1" color={theme.colours.portlBlue} mb="20px">
            5
          </Typography>
          <Typography
            variant="body1"
            mb="0"
            fontWeight={"bold"}
            color={theme.colours.darkNavy}
          >
            Unique DOOH venue types
          </Typography>
        </Card>
      </Grid>

      {/* Card 2 */}
      <Grid item xs={12} md={3}>
        <Card
          bg={theme.colours.lightGrey}
          border={theme.colours.portlBlue}
          minHeight="130px"
        >
          <Typography variant="h1" color={theme.colours.portlBlue} mb="20px">
            200M+
          </Typography>
          <Typography
            variant="body1"
            mb="0"
            fontWeight={"bold"}
            color={theme.colours.darkNavy}
          >
            Monthly impressions
          </Typography>
        </Card>
      </Grid>

      {/* Card 3 */}
      <Grid item xs={12} md={3}>
        <Card
          bg={theme.colours.lightGrey}
          border={theme.colours.portlBlue}
          minHeight="130px"
        >
          <Typography variant="h1" color={theme.colours.portlBlue} mb="20px">
            50
          </Typography>
          <Typography
            variant="body1"
            mb="0"
            fontWeight={"bold"}
            color={theme.colours.darkNavy}
          >
            Cities across Canada and USA
          </Typography>
        </Card>
      </Grid>

      {/* Card 4 */}
      <Grid item xs={12} md={3}>
        <Card
          bg={theme.colours.lightGrey}
          border={theme.colours.portlBlue}
          minHeight="130px"
        >
          <Typography variant="h1" color={theme.colours.portlBlue} mb="20px">
            10K
          </Typography>
          <Typography
            variant="body1"
            mb="0"
            fontWeight={"bold"}
            color={theme.colours.darkNavy}
          >
            Network screens
          </Typography>
        </Card>
      </Grid>
      <Grid item mt={"20px"}>
        <Button href="/vendors#our-inventory">View our inventory</Button>
      </Grid>
    </Section>
  );
}

export default Section3;
