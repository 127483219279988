import React from "react";
import { Grid, Box, ThemeProvider } from "@mui/material";
import textTheme, { theme } from "../../theme";

interface SectionProps {
    bg: string;
    pt?: boolean; // if false, removes paddingTop (for home/Section1)
    pb?: boolean; // if true, adds extra paddingBottom (for home/Section4)
    zIndex?: number; // default is 1
    children: any;
};

export default function Section({ bg, pt=true, pb=false, zIndex=1, children }: SectionProps) {
    return (
    // provides access to theme fonts
    <ThemeProvider theme={textTheme}>
        {/* sets background color beyond maxWidth */}
        <Box display='flex' position='relative' zIndex={zIndex} margin='auto' width='100%' style={{background: bg}}>
            {/* sets maxWidth */}
            <Box display='flex' margin='auto' maxWidth='1080px'>
                {/* sets padding/margin/alignment */}
                <Grid container xs={12} sx={{ position: 'relative',
                marginX: 'auto', justifyContent: 'center', textAlign:'center',
                paddingBottom: {xs: (pb && '100px') || '50px', md: (pb && '230px') || theme.paddings.section},
                paddingTop: {xs: '50px', md: (pt && theme.paddings.section) || '0'}}} >
                    {children}
                </Grid>
            </Box>
        </Box>
    </ThemeProvider>
    );
};