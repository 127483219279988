import React from 'react';
import { theme } from "../../theme";
  
interface LineProps {
    colour?: string;
  }
  
  function Line({ colour }: LineProps) {
    return(
        <hr style={{height: '5px', width: '125px', backgroundColor: colour || theme.colours.portlBlue , 
        borderWidth: '0', marginBottom: theme.paddings.text, display:'inline-flex'}} />
    );
};

export default Line;