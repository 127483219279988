import React from "react";
import { Box } from "@mui/material";
import { theme } from "../../theme";
import "./card.css"

interface CardProps {
  bg: string;
  border: string;
  minHeight?: string;
  children: any;
}

export default function Card({
  bg,
  border,
  minHeight = "200px",
  children,
}: CardProps) {
  return (
    <Box
      className="card"
      display="flex"
      flexDirection="column"
      sx={{
        position: "relative",
        background: bg,
        border: "5px solid",
        borderColor: border,
        borderRadius: "20px",
        margin: "20px",
        justifyContent: "top",
        textAlign: "center",
        minHeight: { xs: "0", md: minHeight },
        padding: theme.paddings.text,
      }}
    >
      {children}
    </Box>
  );
}
