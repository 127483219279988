import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import { theme } from "../../theme";
import Line from "../../components/Line/line";
import Section from "../../components/Section/section";
import Card from "../../components/Card/card";
import { styled } from "styled-components";

///////////////// STYLED COMPONENTS ////////////////
const Icon = styled.img`
  text-align: center;
  height: 55px;
  margin-bottom: ${(props) => props.theme.paddings.text};
`;

//////////////////// FUNCTIONS ////////////////////
function Section1() {
  return (
    <>
      <Box
        height={{ xs: "200%", md: "120%" }}
        sx={{
          background: theme.colours.lightGrey,
          position: "absolute",
          zIndex: 1,
          width: "100%",
        }}
      >
        <img
          src="./line_grid.svg"
          style={{
            marginTop: "100px",
            marginBottom: "100px",
            width: "100%",
            objectFit: "cover",
          }}
        />
      </Box>
      <Section bg={"transparent"}>
        <Grid item xs={12} px={{ xs: "8%", md: 0 }}>
          <Line />
          <Typography variant="h2">Traditional DOOH</Typography>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={4}>
            <Card
              bg={theme.colours.white}
              border={theme.colours.grey}
              minHeight="315px"
            >
              <Icon src="./icons/thumbs_down.svg" />
              <Typography variant="h3" color={theme.colours.black}>
                Scattered <br/>reach
              </Typography>
              <Typography variant="body1" mb="0">
                Juggling multiple vendors for wide coverage leads to complex,
                time-consuming campaign management.
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card
              bg={theme.colours.white}
              border={theme.colours.grey}
              minHeight="315px"
            >
              <Icon src="./icons/thumbs_down.svg" />
              <Typography variant="h3" color={theme.colours.black}>
                Imprecise targeting
              </Typography>
              <Typography variant="body1" mb="0">
                The lack of precision in ad placements makes reaching specific
                demographics a challenge, resulting in
                less-than-ideal returns.
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card
              bg={theme.colours.white}
              border={theme.colours.grey}
              minHeight="315px"
            >
              <Icon src="./icons/thumbs_down.svg" />
              <Typography variant="h3" color={theme.colours.black}>
                High costs, low efficiency
              </Typography>
              <Typography variant="body1" mb="0">
                Traditional DOOH models often come with hefty costs and
                inefficiencies, especially when handling multiple vendor
                relationships and campaign tracking.
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Section>
    </>
  );
}

export default Section1;
