import React from "react";
import { Box, Grid, Link, Typography } from "@mui/material";
import Line from "../../components/Line/line";
import Section from "../../components/Section/section";
import { Button } from "../../components/Button/button";

function Section5() {
  return (
    <>
      <Box sx={{ height: { xs: "130%", md: "110%" } }}>
        <img
          src="./our_story_bg.png"
          style={{
            width: "100%",
            height: "inherit",
            objectFit: "cover",
            position: "absolute",
            zIndex: 1,
          }}
        />
      </Box>
      <Section bg={"transparent"}>
        <Grid item xs={12} px={{ xs: "8%", lg: "0" }}>
          <Line />
          <Typography variant="h2">Our story</Typography>
          <Typography variant="body1" marginX="auto">
            Born out of the rideshare revolution, we recognized the untapped
            potential of advertising in dynamic spaces. We understood the power
            of making DOOH advertising more accessible, targeted, and
            ultimately, more profitable for all stakeholders. <br />
            <br />
            Today, we're at the forefront of transforming the DOOH landscape.
            Through advanced analytics, flexible hardware solutions, and
            strategic partnerships, we're making out-of-home advertising more
            innovative, efficient, and rewarding than ever before. <br />
            <br />
            <b>Join us on this journey to reshape the world of advertising.</b>
          </Typography>
        </Grid>
        <Grid item mt="30px" mr={{ xs: 0, md: "30px" }}>
          <Button href="/ads">Visit Portl Ads</Button>
        </Grid>
        <Grid item mt="30px">
          <Button kind="outline" href="/vendors">
            Visit Portl Vendors
          </Button>
        </Grid>
      </Section>
    </>
  );
}

export default Section5;
