import React from "react";
import styled from "styled-components";
import { Typography, Grid, Box } from "@mui/material";
import { Button } from "../../components/Button/button";
import Section from "../../components/Section/section";

///////////////// STYLED COMPONENTS ////////////////
const Video = styled.video`
  width: 100%;
  position: absolute;
  height: 115%;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: top center;
`;

const ImageBG = styled.img`
  width: 100%;
  position: absolute;
  height: 125%;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: top center;
`;

//////////////////// FUNCTIONS ////////////////////

function HeroSection() {
  return (
    <>
      {/* image displayed on mobile */}
      <ImageBG src="./hero_bg_mobile.webp" />
      {/* video played on widescreen */}
      <Box display={{ xs: "none", md: "inline" }}>
        <Video autoPlay={true} loop={true} controls={false} playsInline muted>
          <source src="./hero_background.webm" type="video/webm" />
        </Video>
      </Box>
      <Section bg="transparent">
        <Grid item xs={12} pt={{ xs: 15, md: 18 }}>
          <Typography variant="h1" px={{ xs: 4, sm: 10, md: 10, lg: 20 }}>
            Transforming the DOOH landscape, together
          </Typography>
          <Typography
            variant="body1"
            mx="auto"
            px={{ xs: 4, sm: 10, md: 10, lg: 20 }}
          >
            Portl is spearheading a groundbreaking transformation in the Digital
            Out-of-Home (DOOH) advertising sphere. We're championing
            profitability for venue owners, ushering in unparalleled efficiency
            for advertisers, and curating captivating experiences for consumers.
          </Typography>
          <Button kind="darkNavy" href="#what-we-do">
            Learn more
          </Button>
        </Grid>
      </Section>
    </>
  );
}

export default HeroSection;
