import React, {useEffect} from "react";
import NavBar from "../../components/NavBar/navbar";
import Footer from "../../components/Footer/footer";
import HeroSection from "./adsHero";
import Section1 from "./adsSection1";
import Section2 from "./adsSection2";
import Section3 from "./adsSection3";
import Section4 from "./adsSection4";
import Section5 from "./adsSection5";

function Ads() {
  useEffect(() => {
    document.title = "Portl Media | Ads";
  }, []);

  return (
    <>
      <NavBar page="ads" />
      <HeroSection />
      <Section1/>
      <Section2/>
      <Section3/>
      <Section4/>
      <Section5/>
      <Footer />
    </>
  );
}

export default Ads;
