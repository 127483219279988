import React from 'react';
  
interface LogoProps {
    colour: string;
    size: string;
  }
  
function Logo({ colour, size }: LogoProps) {
    return(
        <svg style={{position: 'relative', width: 'inherit', height: size}} xmlns="http://www.w3.org/2000/svg" width="1054.092" height="436.176" viewBox="0 0 1054.092 436.176">
        <g id="Component_14" data-name="Component 14" transform="translate(-34.069 -105.246)">
            <path id="Path_2593" data-name="Path 2593" d="M181.74-254.436q34.95,0,63.609,16.077a115.442,115.442,0,0,1,44.969,45.2q16.31,29.125,16.31,67.8t-16.31,67.8a115.442,115.442,0,0,1-44.969,45.2Q216.69,3.728,181.74,3.728q-48,0-75.492-30.29V90.4h-72.7V-250.708h69.434v28.892Q130.014-254.436,181.74-254.436ZM169.158-55.92q27.96,0,45.9-18.873T233-125.354q0-31.688-17.941-50.561t-45.9-18.873q-27.96,0-45.9,18.873t-17.941,50.561q0,31.688,17.941,50.561T169.158-55.92ZM460.408,3.728q-39.61,0-71.065-16.543t-49.163-45.9q-17.708-29.358-17.708-66.638t17.708-66.638q17.708-29.358,49.163-45.9t71.065-16.543q39.61,0,70.832,16.543a123.364,123.364,0,0,1,48.93,45.9q17.708,29.358,17.708,66.638T580.17-58.716a123.363,123.363,0,0,1-48.93,45.9Q500.018,3.728,460.408,3.728Zm0-59.648q27.96,0,45.9-18.873t17.941-50.561q0-31.688-17.941-50.561t-45.9-18.873q-27.96,0-46.134,18.873T396.1-125.354q0,31.688,18.174,50.561T460.408-55.92ZM701.8-217.622q13.048-18.174,35.183-27.494t51.027-9.32v67.1q-12.116-.932-16.31-.932-31.222,0-48.93,17.475t-17.708,52.425V0h-72.7V-250.708H701.8ZM987.92-12.116Q977.2-4.194,961.591-.233a133.116,133.116,0,0,1-32.853,3.961q-44.736,0-69.2-22.834t-24.465-67.1V-189.2H796.394v-55.92h38.678v-61.046h72.7v61.046h62.444v55.92H907.768V-87.142q0,15.844,8.155,24.465t23.067,8.621q17.242,0,29.358-9.32Zm27.028-333.656h72.7V0h-72.7Z" 
            transform="translate(0.517 451.018)" fill={colour}/>
        </g>
        </svg>
    );
};

export default Logo;